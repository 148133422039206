// @flow
import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { ClassicArrow } from '../components/ArrowLink';
import Footer from '../components/Footer';
import GetStarted from '../components/GetStarted';
import Header from '../components/header';
import LearnMore from '../components/LearnMore';
import Page from '../components/Page';
import Section from '../components/Section';
import { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, query } from '../style';
import { GOOGLE_MAP_LINK, GOOGLE_MAP_LINK_2 } from '../utils/constants';
import type { ImageSharp, ImageType } from '../types';
import T from '@material-ui/core/Typography';

export default class Index extends React.Component<{}> {
  render() {
    return (
      <Page title="Contact & Locations" style={{ background: '#6772e5' }}>
        <Header thin light simpleBackground="#6772e5" />

        <StyledSection color="#6772e5">
          <Title>Reach out to us</Title>
          <ContactGroup>
            <Contact>
              <ContactLabel>Phone</ContactLabel>
              <Phone href="tel:+12088552900">1 (208) 855-2900</Phone>
            </Contact>
            <Contact>
              <ContactLabel>Email</ContactLabel>
              <Email href="mailto:info@spineidaho.com">
                info@spineidaho.com
              </Email>
            </Contact>
            <Contact>
              <ContactLabel>Fax</ContactLabel>
              <Phone fax>1 (208) 898-9877</Phone>
            </Contact>
          </ContactGroup>
          <Maps>
            <Title>Our Locations</Title>
            <ContactPoint>
              <Label>MERIDIAN OFFICE</Label>
              <Group>
                <Address>
                  360 E. Montvue Dr.
                  <br />
                  Meridian, Idaho 83642
                </Address>
                {/* <ClassicArrow
                  style={{ color: 'white' }}
                  target="_blank"
                  to={GOOGLE_MAP_LINK}
                >
                  Get directions
                </ClassicArrow> */}
                <Hours>Monday - Friday: 8:00am - 5:00pm</Hours>
              </Group>
            </ContactPoint>
            <iframe
              width="600"
              height="450"
              frameBorder="0"
              style={{
                border: 0,
                width: '100%',
                display: 'flex',
                background: 'papayawhip',
                marginTop: 30,
              }}
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJeeHHEkC0r1QRBIgL0NVOVFs&key=AIzaSyD49IkKld-olyY65XQGC_BavfFoUULh-qE"
              allowFullScreen
            />
          </Maps>

          <Maps>
            <ContactPoint>
              <Label>CALDWELL OFFICE</Label>
              <Group>
                <Address>
                  1906 Fairview Ave Ste 420 <br />
                  Suite A <br />
                  Caldwell, Idaho 83605
                </Address>
                {/* <ClassicArrow target="_blank" to={GOOGLE_MAP_LINK_2}>
                  Get directions
                </ClassicArrow> */}
                <Hours>Monday - Friday: 8:00am - 5:00pm</Hours>
              </Group>
            </ContactPoint>
            <iframe
              width="600"
              height="450"
              frameBorder="0"
              style={{
                border: 0,
                width: '100%',
                display: 'flex',
                background: 'papayawhip',
                marginTop: 30,
              }}
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ69rZvUG0r1QR0x4Y3TKcio4&key=AIzaSyD49IkKld-olyY65XQGC_BavfFoUULh-qE"
              allowFullScreen
            />
          </Maps>
        </StyledSection>
        <LearnMore light />
        <Footer />
      </Page>
    );
  }
}

const Contact = styled.div`
  flex: 1 0 auto;
  margin: 10px 0px;
  ${query(MEDIUM_BREAKPOINT)`
    margin: 10px 20px;
  `}
`;

const Title = styled.h1`
  && {
    color: white;
    margin-top: 0px;
    /* margin-left: 20px; */
    ${query(LARGE_BREAKPOINT)`
      font-size: 40px;
      margin-bottom: 50px;
      margin: 0px;
    `};
  }
`;

const MapLink = styled.a`
  && {
    text-decoration: none;
    text-shadow: none;
    background-image: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Address = styled.div`
  && {
    color: white;
    font-size: 20px;
    line-height: 23px;
  }
`;

const Maps = styled.section`
  && {
    margin-top: 50px;
  }
`;

const ContactPoint = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
    /* max-width: 490px; */
    flex-direction: column;
    align-items: flex-start;
    ${query(MEDIUM_BREAKPOINT)`
    margin-left: 20px;
  `};
  }
`;

const Label = styled.h4`
  && {
    width: 250px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 1.3em;
  }
`;

const Group = styled.div`
  && {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    flex-wrap: wrap;
    /* margin-left: 30px; */
  }
`;
const ContactGroup = styled.div`
  && {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: flex-start;
    flex-wrap: wrap;
    ${query(MEDIUM_BREAKPOINT)`
      justify-content: center;
    `}
  }
`;

const Phone = styled.a`
  && {
    padding: 10px;
    text-decoration: none;
    text-shadow: none;
    line-height: initial;
    background: none;
    font-size: 26px;
    color: white
      ${({ fax }) =>
        !fax &&
        `&:hover {
      text-decoration: underline;
    }`};
  }
`;

const ContactLabel = styled.h4`
  && {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin: 5px 10px;
    /* font-size: 16px; */
  }
`;

const Hours = styled.div`
  && {
    margin-top: 10px;
    font-size: 20px;
    color: white;
  }
`;

const Email = styled.a`
  && {
    color: white;
    line-height: initial;
    margin-top: 5px;
    text-decoration: none;
    text-shadow: none;
    background: none;
    font-size: 26px;
    padding: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledSection = styled(Section)`
  && {
    padding: 20px;
  }
`;
